<template>
  <div @click="toggleMode" class="cursor-pointer">
    <div class="bg-gray-200 bg-opacity-50 p-4 rounded-xl h-full">
      <div class="flex justify-between">
        <div class="flex">
          <img :src="'images/tokens/'+token.store.token+'.png'" class="h-5 md:h-6 mr-1 md:mr-2"/>
          <div class="font-bold text-gray-500 text-sm md:text-base">
            {{token.store.name}}
          </div>
        </div>
        <span class="text-sm md:text-base" :class="token.change<0?'text-red-500':'text-green-500'">
          <span v-if="mode==0"><span v-if="token.change>0">+</span>{{token.change.toFixed(2)}}%</span>
          <span v-else-if="mode==1">${{$common.formatTokenLong($BN(token.current.price))}}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import {ref} from 'vue';

  export default {

		props: {
			token: Object
		},

    setup() {
      const mode = ref(0)
      return { mode }
    },

    mounted() {
    },

    methods: {

      toggleMode() {
        if(this.mode+1 > 1) this.mode = 0
        else this.mode ++
      }

    }

  }

</script>
