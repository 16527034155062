<template>
  <div>

    <!--NEWS-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        
        <div class="flex justify-between mb-4">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib News</div>
          <div class="text-sm text-gray-400 pb-1">
            <div class="text-right"><span class="text-red-500">Red</span>=FUD, <span class="text-green-500">Green</span>=LFG</div>
            <div v-if="tokens!=null" class="text-xs text-right">updated {{$moment.utc(tokens[0].current.created).fromNow()}}</div>
          </div>
        </div>

        <div class="text-base text-gray-600 mb-2">
          
          <!-- QUICK QUOTE -->
          <div v-if="tokens!=null" @click="toggleQuoteMode" class="cursor-pointer mt-0 md:mt-1 flex">
            <div class="bg-opacity-10 flex-1 px-2 rounded-lg text-center border-gray-200 border-2 mt-1 mb-3 text-xs sm:text-sm md:text-base">
              
              <div class="my-2">
                <div v-for="token of tokens" v-bind:key="token.store.id" class="mr-2 lg:mr-5 inline-block">
                  <span class="text-gray-500 font-bold">{{token.store.short.toUpperCase()}}</span>
                  <span class="ml-1" :class="token.change<0?'text-red-500':'text-green-500'">
                    <span v-if="quoteMode==0"><span v-if="token.change>0">+</span>{{token.change.toFixed(2)}}%</span>
                    <span v-else-if="quoteMode==1">${{$common.formatTokenLong($BN(token.current.price))}}</span>
                  </span>
                </div>
              </div>

              <hr/>
              
              <div class="my-2">
                <div class="mr-2 lg:mr-5 inline-block">
                  <span class="text-gray-500 font-bold">Holders</span>
                  <span class="ml-1" :class="holderChange<0?'text-red-500':'text-green-500'">
                    <span v-if="quoteMode==0"><span v-if="holderChange>0">+</span>{{holderChange.toFixed(2)}}%</span>
                    <span v-else-if="quoteMode==1">{{$common.numberCommas(report.holders)}}</span>
                  </span>
                </div>
                
                <div class="mr-2 lg:mr-5 inline-block">
                  <span class="text-gray-500 font-bold">Burned</span>
                  <span class="ml-1" :class="burnChange<0?'text-red-500':'text-green-500'">
                    <span v-if="quoteMode==0">+{{$common.abbreviateNumber(burnChange,2)}}</span>
                    <span v-else-if="quoteMode==1">{{$common.abbreviateNumber(report.burn_total,2)}}</span>
                  </span>
                </div>
              </div>

            </div>
          </div>
          <!-- HOLDER/BURN QUOTE -->


          <!-- NEWS LIST -->
          <div v-for="n of news" v-bind:key="n.id">
            <div class="mt-0 md:mt-1 flex">
              <div class="bg-gray-400 bg-opacity-20 py-2 px-4 my-1 rounded-l-lg w-28 text-center" :class="n.fud==true?'text-red-600':'text-green-600'">
                <span class="font-mono text-sm">{{$moment(n.dt).format("MM.DD.YY")}}</span>
              </div>
              <div class="bg-gray-400 bg-opacity-10 flex-1 py-2 px-4 my-1 rounded-r-lg overflow-hidden">
                <span>{{n.title}}</span>
                <a v-if="n.url!=null" class="text-blue-500" target="_blank" :href="n.url"><i class="fa fa-external-link-alt ml-2"/></a>
              </div>
            </div>
          </div>
          <!-- NEWS LIST -->

        </div>
        <div class="text-right text-xs text-gray-400">* only impactful news that is definite, things that might happen are not posted</div>
      </div>
    </div>
    <!--NEWS-->


    <!--ALERTS-->
    <div class="container md:px-4 mx-auto mt-6 mb-6">
      <div class="p-6 md:p-10 sm:rounded-lg border-t-2 border-b-2 sm:border-2 card-bg">
        
        <div class="flex justify-between mb-4">
          <div class="font-bold text-gray-500 text-2xl md:text-3xl">Shib Alerts</div>
          <div class="text-sm text-gray-400 pb-1">
            <!--<div v-if="tokens!=null" class="text-xs text-right">follow our <a :href="$store.state.TWITTER_URL" target="_blank" class="text-blue-600 underline">twitter account</a> to receive these alerts</div>-->
          </div>
        </div>

        <div class="text-base text-gray-600 mb-2">

          <!-- ALERT LIST -->
          <div v-for="n of alerts" v-bind:key="n.id">
            <div class="mt-0 md:mt-1 flex">
              <div class="bg-gray-400 bg-opacity-20 py-2 px-4 my-1 rounded-l-lg w-28 text-center">
                <div class="font-mono text-sm text-gray-500">{{$moment.utc(n.dt).local().format("MM.DD.YY")}}</div>
                <div class="font-mono text-xs text-gray-400">{{$moment.utc(n.dt).local().format("hh:mm A")}}</div>
              </div>
              <div class="bg-gray-400 bg-opacity-10 flex-1 py-2 px-4 my-1 rounded-r-lg overflow-hidden">
                <span v-html="linkify(n.txt)"/>
              </div>
            </div>
          </div>
          <!-- ALERT LIST -->

        </div>
      </div>
    </div>
    <!--ALERTS-->


  </div>
</template>


<style lang="scss" scoped>
</style>


<script>
  import { ref } from 'vue'
  import TokenQuote from '../components/TokenQuote'
  import IdleReloadMixin from '../mixins/IdleReloadMixin'
  import ServerMixin from '../mixins/ServerMixin'

  const REFRESH_SECS = 60

  export default {
    components: {TokenQuote},

    mixins: [IdleReloadMixin,ServerMixin],

    setup() {
      const news = ref([])
      const report = ref()
      const report7 = ref()
      const tokens = ref()
      const quoteMode = ref(0)
      const holderChange = ref(1)
      const burnChange = ref(1)
      const alerts = ref([])

      return {
        news,
        alerts,
        report,report7,
        tokens,
        quoteMode,
				holderChange,
				burnChange,
      }
    },

    created() {
      document.title = "ShibReport - News & Alerts"
    },

    async mounted() {
      await this.refreshData()
      this.idleReloadStart(REFRESH_SECS,this.refreshData)
    },

    async unmounted() {
      this.idleReloadStop()
    },
    
    methods: {

      async refreshData() {
        console.log("refreshData")
        this.$emitter.emit('spinner-on')

        this.news = await this.getNewsFull()
        this.alerts = await this.getAlerts()
        this.report7 = await this.getReportRange('range','7') //Get last 7 days
        this.report = this.report7.data[0] //Current

        //Update tokens
        let tokensArr = []
        for(let n of this.$store.state.TOKENS) {
          let data = (await this.getPriceRange(n.token,'range',2)).data
          tokensArr.push({
            store: n,
            data,
            el: null,
            last: data[data.length-2],
            current: data[data.length-1],
            change: ((data[data.length-1].price-data[data.length-2].price)/data[data.length-1].price) * 100
          });
        }
        this.tokens = tokensArr
        this.token = tokensArr[0]

				this.holderChange = ((this.report.holders-this.report7.data[1].holders)/this.report.holders) * 100
				this.burnChange = this.report.burn_total-this.report7.data[1].burn_total

        this.$emitter.emit('spinner-off')
        this.idleReloadStart(REFRESH_SECS,this.refreshData)
      },
      
      toggleQuoteMode() {
        if(this.quoteMode+1 > 1) this.quoteMode = 0
        else this.quoteMode ++
      },

      linkify(inputText) {
        var replacedText, replacePattern1, replacePattern2, replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" class="text-blue-600 underline"><i class="fa fa-external-link-alt mx-1"></i></a>');

        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" class="text-blue-600 underline"><i class="fa fa-external-link-alt mx-1"></i></a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" class="text-blue-600 underline"><i class="fa fa-external-link-alt mx-1"></i></a>');
        
        replacedText = replacedText.replace('BIG','&nbsp;BIG');
        replacedText = replacedText.replace('DAILY','&nbsp;DAILY');

        return replacedText;
      }

    }

  }
</script>
